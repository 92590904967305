import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useAuth } from '../../contexts/authContext';
import { user_submissions } from '../../services/api';
import { useNavigate } from 'react-router-dom';

// assets
import { IoCaretForwardOutline } from "react-icons/io5";
export default function Submissions() {
  const [isLoading, setIsLoading] = useState(true);
  const [respond, setRespond] = useState([]);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await user_submissions(user.user_id);
        setRespond(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [user?.user_id]);

  const handleSelect = (item) => {
    navigate(`/trainee/submissions/${item.submission_id}`, { state: { item } });
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="max-w-7xl min-h-screen mx-auto flex flex-col justify-between py-3">
      <Header dark />
      <div className="flex-grow w-full max-w-[950px] mx-auto text-right pt-5 flex flex-col gap-2 md:gap-3">
        <button
          onClick={handleGoBack}
          className="flex gap-2 w-fit items-center px-4 py-2 text-darkBlue relative group"
        >
          <IoCaretForwardOutline />

          <span>الرجوع</span>
          <span className='absolute bottom-0 left-1/2 w-0 h-px bg-darkBlue transition-all duration-50 ease-out group-hover:left-0 group-hover:w-full'></span>
        </button>
        <h2 className="text-darkBlue text-4xl mx-5 mb-2">التقيمات</h2>
        <div className="bg-darkBlue w-full flex flex-col gap-2 py-5 md:py-10 px-10 rounded-10 text-white">
          {isLoading ? (
            <div className="gap-4 flex flex-col bg-white rounded-10 p-3">
              <div className="h-4 bg-gray-300 rounded w-5/6 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-2/3 animate-pulse"></div>
            </div>
          ) : (
            <>
              {respond.length === 0 ? (
                <div className="space-y-4 flex flex-col bg-white rounded-10 px-3 py-5 h-24">
                  <p className='font-bold text-darkBlue my-auto text-2xl'>لا يوجد جلسة مرسلة</p>
                </div>
              ) : (
                respond.map((item, i) => (
                  <div key={i} className='bg-white w-full rounded-10 p-3 text-darkBlue md:text-xl flex flex-col gap-2 font-bold' >
                    <p >
                      ترافعت بقضية <span className="font-semibold">{item.submission_type}</span>
                    </p>
                    <p >
                      الدور <span className="font-semibold">{item.trainee_role}</span>
                    </p>
                    <p >
                      الحالة{' '}
                      <span className="font-semibold">
                        {item.submission_status === 'new' ? 'لم يتم تقييمه' : 'تم تقييمه '}
                      </span>
                    </p>
                    <button
                      onClick={() => handleSelect(item)}
                      disabled={item.submission_status === 'new'}
                      className={`font-semibold bg-darkBlue text-white rounded-10 w-fit px-4 py-2 mr-auto ${item.submission_status === 'new' && 'bg-darkBlue/60'
                        }`}
                    >
                      {item.submission_status === 'new' ? 'لم يتم تقييمه' : 'عرض التقيم'}
                    </button>
                  </div>
                ))
              )}
            </>
          )}
        </div>

      </div>
      <Footer theme="light" />
    </div >
  );
}
