import React, { useEffect, useState } from 'react'
import { FaStar } from "react-icons/fa6";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Grade = () => {
    const [data, setData] = useState('')

    const { state } = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        if (!state) {
            return navigate('/trainee');
        }

        setData(state.item)

    }, [state, navigate])

    return (
        <div className='max-w-7xl flex flex-col justify-between relative mx-auto min-h-screen py-3 '>
            <Header theme={'light'} />
            <div className='text-right rounded-10 p-5 md:p-10 bg-darkBlue h-full flex-grow '
                style={{ direction: "rtl" }}
            >
                <div className="grid grid-cols-1 py-10 md:grid-cols-2 gap-2 text-white text-xl md:text-2xl"
                >
                    <p>
                        <span className="md:block">رد المحامي :</span> {data.trainer_name}
                    </p>
                    <p className='mt-auto'>
                        <span>القضية:</span> {data.submission_type}
                    </p>
                </div>

                <div className="bg-white w-full h-full p-3 flex flex-col gap-14 text-darkBlue text-xl  rounded-10"                >
                    <div className="flex flex-col gap-5">
                        <p>تفاصيل الرد:</p>
                        <audio controls src={data.voice_recording_link}>

                            لا يدعم متصفحك تشغيل الصوت.
                        </audio>
                    </div>
                    <div className="flex flex-col gap-5">
                        <p>تقييم الرد:</p>
                        <div className="bg-[#37343c] rounded-full py-3 px-5 w-fit flex gap-2 md:gap-5 "

                        >
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    className={`size-5 ${star <= data.rating ? 'text-yellow-400' : 'text-gray-300'
                                        }`}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 flex-grow">
                        <p>
                            ملاحظات للمتدرب:
                        </p>
                        <p className="text-xl p-3"
                            style={{ direction: 'rtl', whiteSpace: 'pre-wrap' }}>
                            {data.notes}
                        </p>

                    </div>
                    <div className="my-3 relative flex justify-center">

                        <Link to='/trainee' className="bg-darkBlue py-3 text-center text-white rounded-full w-3/4 mx-auto hover:bg-hoverPrimary">
                            الرجوع
                        </Link>
                    </div>
                </div>
            </div>
            <Footer theme={'light'} />
        </div>
    )
}

export default Grade;
// temp data
const text = `تحتاج الى التدريب اكثر على:
-النصوص اللغوية    
-اللوائح الخاصة بنظام العمل 
-مراجعة المادة 77 من نظام العمل`
