import React from 'react';

import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from './contexts/authContext';

import Home from './pages/Home'
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import PasswordRest from './pages/auth/PasswordRest';
import PasswordRecovery from './pages/auth/PasswordRecovery';

// Trainee pages: 
import WelcomePage from './pages/TraineePages/WelcomePage';
import Submissions from './pages/TraineePages/Submissions';

// lawyer pages
import WelcomeLawyer from './pages/LaywerPages/WelcomeLawyer';
import GradeSubmission from './pages/LaywerPages/GradeSubmission';
import Upload from './pages/TraineePages/Upload';
import About from './pages/About';
import Grade from './pages/TraineePages/Grade';

const ProtectedRoute = ({ allowedRoles }) => {
  const { user } = useAuth();
  const currentPath = useLocation().pathname;

  if (!user) {
    // User not authenticated, redirect to current page
    console.log("foo")
    return <Navigate to={"/login"} replace />;
  } else if (user && !allowedRoles.includes(user?.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Main Pages */}
          <Route path="*" element={<Navigate to={"/"} replace />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<PasswordRecovery />} />
          <Route path="/new-password" element={<PasswordRest />} />

          {/* Trainee Pages */}

          <Route element={<ProtectedRoute allowedRoles={['trainee']} />}>
            <Route path="/trainee" element={<WelcomePage />} />
            <Route path="/trainee/upload" element={<Upload />} />
            <Route path="/trainee/submissions" element={<Submissions />} />
            <Route path="/trainee/submissions/:submissionID" element={<Grade />} />
          </Route>

          {/* Lawyer Pages */}
          <Route element={<ProtectedRoute allowedRoles={['lawyer']} />}>
            <Route path="/lawyer" element={<WelcomeLawyer />} />
            <Route path="/lawyer/grade/:id" element={<GradeSubmission />} />
          </Route>

          {/* Admin Pages ================================================================ */}
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path="/admin" element={<></>} />
          </Route>

        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
