import { Client, Account, Databases, ID, Permission, Role } from 'appwrite';

export const API_ENDPOINT = process.env.REACT_APP_APPWRITE_ENDPOINT
export const PROJECT_ID = process.env.REACT_APP_APPWRITE_PROJECT

const client = new Client()
    .setEndpoint(API_ENDPOINT)
    .setProject(PROJECT_ID);

const account = new Account(client);
const databases = new Databases(client);

const DATABASE_ID = process.env.REACT_APP_DATABASE_ID;
const USER_COLLECTION_ID = process.env.REACT_APP_APPWRITE_USER_COLLECTION_ID;

const createUserDocument = async ($id, formData) => {

    try {
        const response = await databases.createDocument(
            DATABASE_ID,
            USER_COLLECTION_ID,
            $id,
            {
                user_id: $id,
                email: formData.email,
                name: formData.name,
                phone: formData.phone,
                role: formData.role,
                licenseNumber: formData.licenseNumber || "",
                field: formData.field || ""
            },
            [
                Permission.read(Role.any()),
                Permission.update(Role.any()),
                Permission.delete(Role.any()),
                Permission.write(Role.any())
            ],
        );
        return response;
    } catch (error) {
        console.error("Error creating user document:", error);
        throw error;
    }
};

const getUserDocument = async (userId) => {
    console.log(userId)

    const response = await databases.getDocument(DATABASE_ID, USER_COLLECTION_ID, userId);
    return response
};

export { client, account, databases, DATABASE_ID, USER_COLLECTION_ID, createUserDocument, getUserDocument };