import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
// assets
import logo from "../../assets/images/logo1.png";
import largeLogo from "../../assets/images/largeLogo.png";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";

const Login = () => {
  const { loginUser, user } = useAuth();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (user) {
      navigate('/')
    }
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (validateForm()) {
        await loginUser(formData);

      }
    } catch (error) {
      setErrorMsg("فشل تسجيل الدخول. يرجى التحقق من بياناتك.");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMsg("صيغة البريد الإلكتروني غير صحيحة");
      return false;
    }
    return true;
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center w-screen h-screen">
      <div className="p-5 size-full flex flex-col justify-center items-center">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="h-24 lg:h-28 mx-auto lg:mx-0"
          />
        </Link>
        <form onSubmit={handleSubmit} className="flex flex-col gap-8 justify-center p-4 w-80 lg:w-96">
          <div className="flex flex-col gap-2 text-primary">
            <h2 className="font-bold text-2xl">
              تسجيل الدخول
            </h2>
            <p className=" text-sm">الرجاء ادخال معلومات تسجيل الدخول</p>
          </div>

          <div className="rounded-10 bg-input flex items-center gap-1 w-full py-3 px-2 ">
            <MdOutlineAlternateEmail size={16} color="#868AA5" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="البريد الالكتروني"
              className="flex-1 bg-transparent outline-none placeholder:text-placeholder"
            />
          </div>
          <div className="rounded-10 bg-input flex items-center gap-1 w-full py-3 px-2">
            <RiLockPasswordLine size={16} color="#868AA5" />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="كلمة المرور"
              className="flex-1 bg-transparent outline-none placeholder:text-placeholder"
            />
          </div>
          {errorMsg && <p className="text-red text-sm">{errorMsg}</p>}
          <button
            type="submit"
            disabled={loading}
            className={`${loading ? 'bg-primary/50' : 'bg-primary/85'} text-white hover:bg-hoverPrimary transition-all duration-300 lg:w-40 py-3 px-1 lg:px-8 lg:py-5 rounded-md shadow-md hover:scale-90 active:scale-75 flex justify-center items-center`}>
            {loading ? <span className="border-2 border-l-transparent border-white animate-spin rounded-full size-5"></span>
              : <p>تسجيل الدخول</p>}
          </button>
          <div className="flex justify-between w-full font-medium text-base px-1">
            <p>ليس لديك حساب؟</p>
            <Link to={"/register"} className="text-primary hover:text-hoverPrimary">
              إنشاء حساب
            </Link>
          </div>
        </form>
      </div>

      <div className="bg-gradient-to-b from-primary to-darkBlue hidden xl:min-w-[50%] xl:grid place-items-center">
        <img
          className="w-full "
          src={largeLogo}
          alt="baseratk logo"
        />
      </div>
    </div>
  );
};

export default Login;
