import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

// assets
import lightLogo from "../assets/images/logo1.png";
import darkLogo from "../assets/images/logo2.png";
import { IoIosArrowDown, IoIosLogOut, IoIosNotificationsOutline, IoMdClose } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { CgMenuMotion } from "react-icons/cg";

export default function Header({ dark }) {
    const { user, logoutUser } = useAuth();

    const mainColor = dark ? "primary" : "white";
    const logo = dark ? darkLogo : lightLogo;
    const userType = user?.role === 'lawyer' ? '/lawyer' : "/trainee"

    const [userMenu, setUserMenu] = useState(false);

    return (
        <header className='xl:container mx-auto w-screen'>
            <div className={`flex justify-between items-center py-1 px-3 text-${mainColor} relative`}>
                <div className='flex gap-5 items-center'>
                    <Link to={'/'}>
                        <img className="w-20 object-fill" alt="ﺑﺼﻴﺮﺗﻚ" src={logo} />
                    </Link>
                    <nav className='hidden xl:flex gap-10'>
                        <Link
                            to={userType}
                            className="py-1 relative group"
                        >
                            <span className={`absolute bottom-0 left-1/2 w-0 h-px bg-${mainColor} transition-all duration-50 ease-out group-hover:left-0 group-hover:w-full`}></span>
                            <span>المحتوى</span>
                        </Link>
                        <Link
                            to={'/about'}
                            className="py-1 relative group"
                        >
                            <span className={`absolute bottom-0 left-1/2 w-0 h-px bg-${mainColor} transition-all duration-50 ease-out group-hover:left-0 group-hover:w-full`}></span>
                            <span>من نحن</span>
                        </Link>
                    </nav>
                </div>

                <div className='hidden xl:inline'>
                    {user ?
                        <div className='flex gap-2 items-center relative'>
                            <button className='flex items-center gap-1' onClick={() => setUserMenu(!userMenu)}>
                                <IoIosArrowDown className={`mt-1 size-5 transition-transform ${userMenu && 'rotate-180'}`} />
                                <p>{user?.name}</p>
                            </button>
                            {userMenu && (
                                <div className={`absolute top-10 left-1/2 bg-gray-50 rounded-md shadow-md w-56 p-3`}>

                                    <Link
                                        to={'/profile'}
                                        className="text-primary flex items-center gap-1 py-2 border-b border-gray-300 w-full hover:bg-slate-100"
                                    >
                                        <CiUser />
                                        <span>عرض الملف الشخصي</span>
                                    </Link>

                                    <button
                                        onClick={logoutUser}
                                        className="text-darkRed flex items-center gap-1 py-2 border-b border-gray-300 w-full hover:bg-slate-100"
                                    >
                                        <IoIosLogOut />
                                        <span>تسجيل الخروج</span>
                                    </button>
                                </div>
                            )}
                            <IoIosNotificationsOutline className='size-6' />
                        </div> :
                        <>
                            <Link to={'/login'} className="p-2 text-center text-white transition-all duration-300 hover:text-hoverPrimary hover:border-hoverPrimary hover:bg-white border-2 border-white rounded-full hidden xl:inline-block w-40 mx-2">تسجيل دخول</Link>
                            <Link to={'/register'} className="p-2 text-center text-white transition-all duration-300 hover:text-hoverPrimary hover:border-hoverPrimary hover:bg-white border-2 border-white rounded-full hidden xl:inline-block w-40 mx-2">انشاء حساب</Link>
                        </>
                    }
                </div >
                <DropMenu userType={userType} />
            </div>
        </header >
    )
}
function DropMenu({ userType }) {
    const [show, setShow] = useState(false);
    const { user, logoutUser } = useAuth();

    const toggleMenu = () => {
        setShow(!show);
    };

    return (
        <>
            <div className='flex gap-4 items-center xl:hidden'>
                <p className='font-bold'>{user?.name}</p>
                <button onClick={toggleMenu} className="p-4 xl:hidden">
                    <div className="relative">
                        <div className="flex justify-center items-center">
                            <CgMenuMotion
                                size={32}
                                className={`transition-all absolute duration-300 ${show ? 'opacity-0 rotate-180' : 'opacity-100 rotate-0'}`}
                            />
                            <IoMdClose
                                size={32}
                                className={`transition-all absolute duration-300 ${show ? 'opacity-100 rotate-0' : 'opacity-0 rotate-180'}`}
                            />
                        </div>
                    </div>
                </button>
            </div>
            {show && (
                <>
                    <span
                        onClick={() => setShow(false)}
                        className="w-screen h-screen left-1/2 transform -translate-x-1/2 absolute top-full xl:hidden bg-slate-600/40"
                    ></span>
                    <nav className='xl:hidden w-full h-fit absolute z-10 top-full left-0 bg-gray-100 flex flex-col gap-5 text-lg p-4 text-center text-primary hover:text-hoverPrimary' >
                        <Link to={userType}>المحتوى</Link>
                        <Link to={'/about'}>من نحن</Link>
                        {user ?
                            <>
                                <Link
                                    to={'/profile'}
                                    className="flex justify-center items-center gap-1 w-full text-center"
                                >
                                    <CiUser />
                                    <span>عرض الملف الشخصي</span>
                                </Link>
                                <button
                                    onClick={logoutUser}
                                    className="text-darkRed flex justify-center items-center gap-1 w-full text-center"
                                >
                                    <IoIosLogOut />
                                    <span>تسجيل الخروج</span>
                                </button>
                            </>
                            :
                            <>
                                <Link to={'/login'}>تسجيل دخول</Link>
                                <Link to={'/register'}>انشاء حساب</Link>
                            </>
                        }
                    </nav>
                </>
            )}

        </>
    );
}