import React, { useEffect, useState } from 'react';
import ExploreCourses from "../../componets/ExploreCourses";
import Header from "../../components/Header";
import WelcomeSection from "../../componets/WelcomeSection";
import Notice from "../../componets/Notice";
import Learing from "../../componets/Learing";
import SkillSection from "../../componets/SkillSection";
import Footer from "../../components/Footer";
import { useAuth } from '../../contexts/authContext';
import { FaArrowLeft, FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import court from '../../assets/icons/court.svg'
import Swal from 'sweetalert2';

const WelcomePage = () => {
  const [activeTab, setActiveTab] = useState('home');
  const { user } = useAuth();

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("firstVisit");
    if (!isFirstVisit) {
      Swal.fire({
        title: 'الشروط والأحكام',
        html: `
          <div style="font-weight: bold; text-align: right; direction: rtl; padding: 20px;">
            <p>الشروط والأحكام بمثابة التزام قانوني، لذلك نأمل قراءة الشروط بعناية والموافقة عليها حتى يتمكن المتدرب من الاستفادة التامة من خدمة التدريب.</p>
            <ul style="list-style-type: disc; padding-right: 20px;">
              <li>يجب الحفاظ على سرية المحتوى وعدم تداوله.</li>
              <li>يجب على العميل أن يتقيد بتعليمات وإرشادات الشركة فيما يتعلق بالتدريب.</li>
              <li>تقدم منصة الشركة عدد كبير من المستندات والمعلومات القانونية.</li>
              <li>يعد مخالفاً استخدام أكثر من شخص اشتراكاً واحداً.</li>
              <li>لا يمكن إلغاء أي طلب اشتراك تم سداد رسومه.</li>
              <li>تخضع هذه الشروط لقوانين المملكة العربية السعودية.</li>
            </ul>
            <label style="display: flex; align-items: center; color: #3D5C70; font-weight: bold;">
              <input type="checkbox" id="agreeCheckbox" style="margin-left: 10px;">
              أوافق على الشروط والأحكام
            </label>
          </div>
        `,
        confirmButtonText: 'موافق',
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preConfirm: () => {
          const checkbox = Swal.getPopup().querySelector('#agreeCheckbox');
          if (!checkbox.checked) {
            Swal.showValidationMessage('يجب عليك قبول الشروط والأحكام');
            return false;
          }
          return true;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("firstVisit", "true");
        }
      });
    }
  }, []);

  return (
    <div className="max-w-7xl min-h-screen mx-auto flex flex-col justify-between py-3">
      <Header dark />
      <div className="flex-grow w-full max-w-[950px] mx-auto">
        <WelcomeSection user={user} />
        <div className="flex flex-row gap-8 my-10 lg:mx-10 mx-3">
          <button
            onClick={() => setActiveTab('home')}
            className={`text-darkBlue md:text-xl border-darkBlue pb-1 ${activeTab === 'home' ? 'border-b-2 cursor-auto' : 'text-darkBlue/[0.6]'}`}
          >
            الصفحة الرئيسية
          </button>
          <button
            onClick={() => setActiveTab('court')}
            className={`text-darkBlue md:text-xl border-darkBlue pb-1 ${activeTab === 'court' ? 'border-b-2 cursor-auto' : 'text-darkBlue/[0.6]'}`}
          >
            المحكمة الإفتراضية
          </button>
        </div>
        {activeTab === 'court' ? <Court /> : <Submissions />}
      </div>
      <Footer />
    </div>
  );
};

const Submissions = () => (
  <div className="mx-2 lg:mx-10 my-5 rounded-10 bg-darkBlue py-5 md:py-10 px-10 flex flex-col">
    <h3 className="text-white text-right md:text-3xl my-5">التقيمات</h3>
    <Link
      to="submissions"
      className="group relative w-full rounded-10 text-darkBlue bg-white text-center py-5 flex gap-2 items-center justify-center"
    >
      <p className='bg-white z-10'>عرض</p>
      <FaArrowLeft className=' absolute group-hover:-translate-x-8 transition-all transform' />
    </Link>
  </div>
);

const Court = () => (
  <div className="mx-5 flex flex-col gap-3">
    <div className="p-5 md:p-10 w-full rounded-[22px] gap-5 bg-darkBlue flex flex-col">
      <div className="w-full md:flex justify-between items-center">
        <h2 className="text-3xl text-white">المحكمة الافتراضية</h2>
        <Link
          to="upload"
          className="hidden md:flex gap-1 bg-secondary justify-between items-center rounded-10 px-10 py-5 hover:bg-secondary/90"
        >
          <p className="text-primary text-lg md:text-xl">رفع الجلسة</p>
          <img src={court} alt="court icon" className="size-8" />
        </Link>
      </div>
      <p className="text-white text-xs lg:text-lg text-right" style={{ direction: 'rtl' }}>
        تجربة افتراضية تفاعلية تحاكي أسلوب المحكمة التقليدية.
      </p>
      <a
        href={process.env.REACT_APP_DOWNLOAD_COURT}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full py-5 bg-white rounded-10 hover:bg-white/90 text-center"
      >
        <p className="text-lg md:text-xl">تنزيل</p>
      </a>
      <Link
        to="upload"
        className="md:hidden bg-secondary flex justify-center items-center rounded-10 w-full gap-5 hover:bg-secondary/90 px-10 py-5"
      >
        <p className="text-primary text-lg md:text-xl">رفع الجلسة</p>
        <img src={court} alt="court icon" className="size-8" />
      </Link>
    </div>
  </div>
);

export default WelcomePage;