import { createContext, useState, useEffect, useContext } from "react";
import {
  account,
  createUserDocument,
  getUserDocument,
  serverClient,
} from "../appwriteConfig";
import { useNavigate } from "react-router-dom";
import { ID, AppwriteException } from "appwrite";
import { BounceLoader } from "react-spinners";
import Swal from "sweetalert2";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUserStatus();
    console.log(user);
  }, []);

  const loginUser = async (formData) => {
    setLoading(true);
    try {
      await account.createEmailPasswordSession(
        formData.email,
        formData.password
      );
      const accountDetails = await account.get();
      console.log(accountDetails);
      const userDocument = await getUserDocument(accountDetails.$id);

      console.log(userDocument);
      setUser({ ...accountDetails, ...userDocument });
      if (userDocument.role === "trainee") {
        navigate("/trainee");
      } else {
        navigate("/lawyer");
      }
    } catch (e) {
      if (e instanceof AppwriteException) {
        console.error(`AppwriteException: ${e.message} (Code: ${e.code})`);
        setLoading(false);
        return Swal.fire({
          title: "معلومات خاطئة",
          text: "كلمة المرور أو البريد الإلكتروني خاطئة",
          icon: "error",
          confirmButtonColor: "#1b2f85",
          confirmButtonText: "تأكيد",
          footer: `<div style="direction: rtl;">
    إذا كان لديك مشكلة في تسجيل الدخول، تواصل معنا على 
    <a href="mailto:Baseratk.sa@gmail.com" style="color: #3D5C70;">Baseratk.sa@gmail.com</a>
</div>`
        });
      } else {
        console.error("An unexpected error occurred:", e);
      }
    }
    setLoading(false);
  };

  const logoutUser = async () => {
    setLoading(true)
    await account.deleteSession("current");
    setUser(null);
    localStorage.clear();
    setLoading(false)
    navigate("/login");
  };

  const registerUser = async (formData) => {
    setLoading(true);
    try {
      const response = await account.create(
        ID.unique(),
        formData.email,
        formData.password,
        formData.name
      );
      console.log(response);
      await createUserDocument(response.$id, formData);
      // await account.createEmailPasswordSession(formData.email, formData.password);
      // const accountDetails = await account.get();
      // setUser(accountDetails);
      Swal.fire({
        title: "تم التسجيل بنجاح",
        text: " الرجاء تسجيل الدخول لحسابك",
        icon: "success",
        confirmButtonColor: "#1b2f85"
      });
      navigate("/login");
    } catch (e) {
      if (e instanceof AppwriteException) {
        console.log("Error registering user:", AppwriteException);
        return Swal.fire({
          title: "مستخدم",
          text: "البريد الالكتروني مستخدم",
          icon: "info",
          confirmButtonColor: "#1b2f85"
        });

      } else {
        console.log(e)
      }
    } finally {

      setLoading(false);
    }
  };

  const checkUserStatus = async () => {
    try {
      const accountDetails = await account.get();

      console.log(accountDetails);
      const userDocument = await getUserDocument(accountDetails.$id);
      console.log(userDocument);
      setUser({ ...accountDetails, ...userDocument });
      console.log(user);
    } catch (e) {
      if (e instanceof AppwriteException) {
        console.error(`AppwriteException: ${e.message} (Code: ${e.code})`);
      } else {
        console.error("An unexpected error occurred:", e);
      }
    } finally {
      setLoading(false);
    }
  };

  const recoverPassword = async (email) => {
    setLoading(true);
    try {
      await account.createRecovery(
        email,
        `https://baseratk.com/new-password`
      );
      Swal.fire({
        title: "تم ارسال رابط استعادة كلمة المرور",
        text: "الرجاء مراجعة بريدك الإلكتروني",
        icon: "success",
        confirmButtonColor: "#1b2f85",
      });
    } catch (e) {
      if (e instanceof AppwriteException) {
        console.error(`AppwriteException: ${e.message} (Code: ${e.code})`);
        return Swal.fire({
          title: "خطأ",
          text: "حدث خطأ أثناء إرسال رابط الاستعادة",
          icon: "error",
          confirmButtonColor: "#1b2f85",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (userId, secret, newPassword) => {
    setLoading(true);
    try {
      await account.updateRecovery(userId, secret, newPassword, newPassword);
      Swal.fire({
        title: "تم إعادة تعيين كلمة المرور بنجاح",
        text: "يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة",
        icon: "success",
        confirmButtonColor: "#1b2f85",
      });
      navigate("/login");
    } catch (e) {
      if (e instanceof AppwriteException) {
        console.error(`AppwriteException: ${e.message} (Code: ${e.code})`);
        return Swal.fire({
          title: "خطأ",
          text: "حدث خطأ أثناء إعادة تعيين كلمة المرور",
          icon: "error",
          confirmButtonColor: "#1b2f85",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const contextData = {
    user,
    loginUser,
    logoutUser,
    registerUser,
    recoverPassword,
    resetPassword
  };

  return (
    <AuthContext.Provider value={contextData}>
      {/* Add a Loader Component here */}
      {loading ? (
        <div className="flex items-center justify-center min-h-screen">
          <BounceLoader color="#3d5c70" />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

// Custom Hook
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
