import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import logo from "../assets/images/largeLogo.png";

const Home = () => {
  return (
    <main
      className="flex flex-col justify-between min-h-screen w-screen"
      style={{ background: 'linear-gradient(to bottom, #062841, #3d5c80 75%,  #ede4d6 )' }}
    >
      <Header />
      <div className="flex flex-col text-center w-full justify-center items-center">
        <img src={logo} alt="Baseratk logo" className="w-fit self-center object-fill" />
        <h2 className="lg:text-3xl text-white text-base"> <span className="text-[#dea778]"> طالب قانون وتدور فرصة للتدريب المهني؟  </span> وصلت لمكانك</h2>
      </div>
      <Footer />

    </main>
  );
};

export default Home;
