import React from "react";
import { FaTiktok, FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

const Footer = ({ dark }) => {
  const BgColor = dark ? 'bg-white' : 'bg-black';
  const iconColor = dark ? 'black' : 'white';

  return (
    <footer className="flex gap-4 p-2 justify-end">
      <a
        href="https://x.com/baseratk_sa"
        className={`${BgColor} rounded-full p-2 cursor-pointer transition-transform lg:opacity-80 transform hover:scale-125 hover:opacity-100`}
      >
        <FaXTwitter color={iconColor} />
      </a>
      <a
        href="https://www.tiktok.com/@baseratk"
        className={`${BgColor} rounded-full p-2 cursor-pointer transition-transform lg:opacity-80 transform hover:scale-125 hover:opacity-100`}
      >
        <FaTiktok color={iconColor} />
      </a>
      <a
        href="https://www.linkedin.com/in/baseratk-sa-689709326"
        className={`${BgColor} rounded-full p-2 cursor-pointer transition-transform lg:opacity-80 transform hover:scale-125 hover:opacity-100`}
      >
        <FaLinkedinIn color={iconColor} />
      </a>
      <a
        href="mailto:Baseratk.sa@gmail.com"
        className={`${BgColor} rounded-full p-2 cursor-pointer transition-transform lg:opacity-80 transform hover:scale-125 hover:opacity-100`}
      >
        <MdAlternateEmail color={iconColor} />
      </a>
    </footer>
  );
};

export default Footer;
