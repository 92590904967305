import React from "react";
import RegisterForm from "../../componets/RegisterFrom";
import blackLogo from "../../assets/images/logo1.png";
import largeLogo from "../../assets/images/largeLogo.png";
import { Link } from "react-router-dom";

function Register() {
  return (
    <div className="relative flex flex-col lg:flex-row justify-between overflow-x-clip max-w-8xl w-full">
      <Link to="/">
        <img
          src={blackLogo}
          alt="baseratk logo"
          className="h-[60px] lg:h-[118px] mx-auto lg:mx-0"
        />
      </Link>

      <div className="grid place-items-center ">
        <RegisterForm />
      </div>

      <div className="lg:flex bg-gradient-to-b from-primary  to-darkBlue min-h-screen hidden lg:w-[50%] justify-center items-center">
        <img
          className="w-[300px] lg:w-[599px] "
          src={largeLogo}
          alt="baseratk logo"
        />
      </div>
    </div>
  )
}

export default Register;
